<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <KTCodePreview v-bind:title="'Đồng bộ bill từ NHANH'">
          <template v-slot:preview>
            <div class="col-12 row" style="padding-left: 0px">
              <b-form-group class="col-12 mb-2">
                <b>Tìm Kiếm :</b>
                <span class="text-primary">(idNhanh)</span>
                <b-input-group label="Id nhanh:">
                  <b-form-input
                    type="text"
                    required
                    v-model="billId"
                    size="sm"
                    class="col-4"
                    placeholder="Nhập idNhanh"
                  ></b-form-input>
                  <b-input-group-append>
                    <b-button
                      variant="primary"
                      size="sm"
                      @click.prevent.self="getBillNhanh()"
                    >
                      <i class="fa fa-search" aria-hidden="true"></i>
                      Tìm trên Nhanh
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger"
                  >(Yêu cầu nhập đúng mã hoá đơn )</small
                >
                <div>
                  <p v-if="textSearchBill === 'loading'">
                    <strong class="text-primary">Loading...</strong
                    ><span
                      role="status"
                      class="spinner-grow spinner-grow-sm text-primary"
                      ><span class="sr-only">Small Spinner</span></span
                    >
                  </p>
                  <div v-else-if="textSearchBill === 'exist'" class=" mb-4">
                    <table id="afterSearch">
                      <tr>
                        <td class="font-weight-bolder pr-1">Hệ thống :</td>
                        <td>
                          <span v-if="!billExist" class="text-danger">
                            không tồn tại
                          </span>
                          <span
                            v-else-if="billExist && billExist.deleted === 1"
                            class="text-success"
                          >
                            đã xoá
                          </span>
                          <span v-else>
                            <router-link
                              :to="{
                                path: `/bills/update-bill`,
                                query: { id: billExist.id }
                              }"
                              target="_blank"
                              >{{ billExist.id }}
                            </router-link>
                          </span>
                        </td>
                        <td rowspan="2" v-if="billExist && !billNhanh">
                          <button
                            style="color: #3f4254; font-size: 12px"
                            @click="showDeleteAlert(billExist.id)"
                          >
                            <i
                              style="font-size: 1rem; color: #d33"
                              class="flaticon2-rubbish-bin-delete-button"
                            ></i>
                            &nbsp; Xóa
                          </button>
                        </td>
                      </tr>
                      <tr>
                        <td class="font-weight-bolder pr-1">Nhanh :</td>
                        <td>
                          <span v-if="!billNhanh" class="text-danger"
                            >không tồn tại</span
                          >
                          <span v-else> {{ billNhanh.id }}</span>
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
              </b-form-group>
            </div>
            <p class="font-weight-bolder mb-4">
              Thông tin:
              <span
                v-if="billNhanh"
                style="font-size:15px; font-weight: 500"
                class="text-primary "
              >
                {{ billNhanh.billTypeName }}
              </span>
            </p>
            <div class="my-2 info" v-if="billNhanh">
              <hr
                class="hr-text"
                data-content="Hoá đơn và khách hàng"
                style="font-weight: 600"
              />
              <b-row>
                <b-col cols="4"
                  ><label class="col-5 font-weight-bolder ">Id Nhanh</label>
                  <b>:</b>
                  <span class="col-7">{{ billNhanh.id }}</span>
                </b-col>

                <b-col cols="4"
                  ><label class="col-5 font-weight-bolder ">Mã hoá đơn</label>
                  <b>:</b>
                  <span class="col-7">{{ billNhanh.billNumber }}</span>
                </b-col>
                <b-col cols="4">
                  <label class="col-5 font-weight-bolder ">Từ cửa hàng</label>
                  <b>:</b>
                  <span class="col-7">{{ billNhanh.storeName }}</span>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="4"
                  ><label class="col-5 font-weight-bolder ">Nhà cung cấp</label>
                  <b>:</b>
                  <span class="col-7">{{ billNhanh.providerId }}</span>
                </b-col>
                <b-col cols="4"
                  ><label class="col-5 font-weight-bolder "
                    >Nguồn hoá đơn</label
                  >
                  <b>:</b>
                  <span class="col-7">{{ billNhanh.orderSourceId }}</span>
                </b-col>
                <b-col cols="4">
                  <label class="col-5 font-weight-bolder ">Đến cửa hàng</label>
                  <b>:</b>
                  <span class="col-7">{{ billNhanh.relatedStoreName }}</span>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="4"
                  ><label class="col-5 font-weight-bolder "
                    >Mã khách hàng</label
                  >
                  <b>:</b>
                  <span class="col-7">{{ billNhanh.customerId }}</span>
                </b-col>
                <b-col cols="4"
                  ><label class="col-5 font-weight-bolder "
                    >Tên khách hàng</label
                  >
                  <b>:</b>
                  <span class="col-7">{{ billNhanh.customerName }}</span>
                </b-col>
                <b-col cols="4">
                  <label class="col-5 font-weight-bolder "
                    >SĐT khách hàng</label
                  >
                  <b>:</b>
                  <span class="col-7">{{ billNhanh.customerMobile }}</span>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="4"
                  ><label class="col-5 font-weight-bolder ">Địa chỉ</label>
                  <b>:</b>
                  <span class="col-7">{{ billNhanh.customerAddress }}</span>
                </b-col>
                <b-col cols="4">
                  <label class="col-5 font-weight-bolder "
                    >Hoá đơn liên kết</label
                  >
                  <b>:</b>
                  <span class="col-7">{{
                    billNhanh.relatedBillId || billNhanh.billGiftId
                  }}</span>
                </b-col>
              </b-row>
              <b-col class="row" cols="12">
                <label class="col-2 font-weight-bolder ">GCKH:</label>
                <span class="col-12">{{ billNhanh.customerNote }}</span>
              </b-col>
              <b-row>
                <b-col cols="4">
                  <label class="col-5 font-weight-bolder ">Tổng tiền SP</label>
                  <b>:</b>
                  <span class="col-7">{{ formatMoney(totalMoneyItems) }} </span>
                </b-col>
                <b-col cols="4">
                  <ul class="col-5 list-unstyled">
                    <li>
                      <span class="font-weight-bolder ">Chiết khấu SP</span>
                    </li>
                    <li>
                      <span class="font-weight-bolder ">Chiết khấu Tổng</span>
                    </li>
                  </ul>

                  <b class="mt-2">:</b>
                  <ul class="col-7 list-unstyled">
                    <div>
                      <li>
                        <span>{{ formatMoney(totalDiscountItems) }}</span>
                      </li>
                      <li>
                        <span>{{ formatMoney(billNhanh.discountAmount) }}</span>
                      </li>
                    </div>
                    <div class="d-inline border-top border-dark pt-1">
                      {{
                        formatMoney(
                          totalDiscountItems + billNhanh.discountAmount
                        )
                      }}
                    </div>
                  </ul>
                </b-col>
                <b-col cols="4" class="d-flex flex-column">
                  <div class="d-flex">
                    <label class="col-5 font-weight-bolder "
                      >Tổng tiền sau CK</label
                    >
                    <b>:</b>
                    <span class="col-7"
                      >{{ formatMoney(billNhanh.totalAmount) }}
                    </span>
                  </div>

                  <div id="base" class="align-self-center"></div>
                </b-col>
              </b-row>
              <hr
                class="hr-text"
                data-content="Tài khoản"
                style="font-weight: 600"
              />
              <b-row>
                <b-col cols="4"
                  ><label class="col-5 font-weight-bolder ">TK quẹt thẻ</label>
                  <b>:</b>
                  <span class="col-7"
                    >{{ billNhanh.creditAccountName }}
                    <span v-if="billNhanh.creditCode"
                      >({{ billNhanh.creditCode }})</span
                    ></span
                  >
                </b-col>
                <b-col cols="4"
                  ><label class="col-5 font-weight-bolder ">ID quẹt thẻ</label>
                  <b>:</b>
                  <span class="col-7">{{ billNhanh.creditAccountId }}</span>
                </b-col>
                <b-col cols="4">
                  <label class="col-5 font-weight-bolder ">Tiền quẹt thẻ</label>
                  <b>:</b>
                  <span class="col-7">{{
                    formatMoney(billNhanh.creditAmount)
                  }}</span>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="4"
                  ><label class="col-5 font-weight-bolder ">TK Tiền mặt</label>
                  <b>:</b>
                  <span class="col-7">{{ billNhanh.cashAccountName }}</span>
                </b-col>

                <b-col cols="4" offset="4"
                  ><label class="col-5 font-weight-bolder ">Tiền mặt</label>
                  <b>:</b>
                  <span class="col-7">{{
                    formatMoney(billNhanh.cashAmount)
                  }}</span>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="4"
                  ><label class="col-5 font-weight-bolder "
                    >TK chuyển khoản</label
                  >
                  <b>:</b>
                  <span class="col-7"
                    >{{ billNhanh.transferAccountName }}
                    <span v-if="billNhanh.transferCode"
                      >({{ billNhanh.transferCode }})</span
                    ></span
                  >
                </b-col>
                <b-col cols="4"
                  ><label class="col-5 font-weight-bolder "
                    >Mã chuyển khoản</label
                  >
                  <b>:</b>
                  <span class="col-7">{{ billNhanh.transferAccountId }}</span>
                </b-col>
                <b-col cols="4">
                  <label class="col-5 font-weight-bolder ">Tiền ckhoản</label>
                  <b>:</b>
                  <span class="col-7">{{
                    formatMoney(billNhanh.transferAmount)
                  }}</span>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="4"
                  ><label class="col-5 font-weight-bolder ">TK trả góp</label>
                  <b>:</b>
                  <span class="col-7"
                    >{{ billNhanh.installMoneyName }}
                    <span v-if="billNhanh.installMoneyCode"
                      >({{ billNhanh.installMoneyCode }})</span
                    >
                  </span>
                </b-col>
                <b-col cols="4"
                  ><label class="col-5 font-weight-bolder ">Mã trả góp</label>
                  <b>:</b>
                  <span class="col-7">{{
                    billNhanh.installMoneyAccountId
                  }}</span>
                </b-col>
                <b-col cols="4">
                  <label class="col-5 font-weight-bolder ">Tiền trả góp</label>
                  <b>:</b>
                  <span class="col-7">{{
                    formatMoney(billNhanh.installedMoneyAmount)
                  }}</span>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="4" offset="8"
                  ><label class="col-5 font-weight-bolder ">Sử dụng điểm</label>
                  <b>:</b>
                  <span class="col-7" v-show="billNhanh.subtractPoint"
                    >{{ formatMoney(billNhanh.subtractPointMoney) }} ({{
                      billNhanh.subtractPoint
                    }})</span
                  >
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="4" offset="8"
                  ><label class="col-5 font-weight-bolder ">Phí trả hàng</label>
                  <b>:</b>
                  <span class="col-7">{{
                    formatMoney(billNhanh.returnFee)
                  }}</span>
                </b-col>
              </b-row>

              <hr
                class="hr-text"
                data-content="Nhân viên"
                style="font-weight: 600"
              />
              <b-row>
                <b-col cols="4"
                  ><label class="col-3 font-weight-bolder ">NVBH</label>
                  <b>:</b>
                  <span class="col-9">{{ billNhanh.saleName }}</span>
                </b-col>
                <b-col cols="4"
                  ><label class="col-3 font-weight-bolder ">HTKT</label>
                  <b>:</b>
                  <span class="col-9">{{ billNhanh.technicalName }}</span>
                </b-col>
              </b-row>
              <b-col class="row" cols="12">
                <label class="col-2 font-weight-bolder ">GCBH:</label>
                <span class="col-12">{{ billNhanh.saleNote }}</span>
              </b-col>
            </div>
            <p class="font-weight-bolder mb-4">
              Danh sách sản phẩm:
              <span v-if="billNhanh">
                <span
                  v-if="billNhanh.haveGift"
                  class="text-success"
                  style="font-weight :500;font-size:15px;"
                >
                  <i
                    style="color: #2e7d32"
                    class="fas fa-gift ml-4 mr-1"
                    title="Quà tặng"
                  ></i
                  >Hoá đơn có quà tặng</span
                >
              </span>
            </p>
            <b-table
              class="table-bordered table-hover col-md-12"
              :fields="fields"
              :items="listBillItem"
              v-if="renderComponent"
            >
              <template v-slot:cell(productName)="row">
                <div>
                  {{ row.item.productName }}
                  <p class="text-primary" v-if="row.item.imei">
                    IMEI: {{ row.item.imei }}
                  </p>
                  <div v-if="row.item.gifts">
                    <table>
                      <tr
                        v-for="(items, i) in row.item.gifts"
                        :key="i"
                        style="border-top: 1px solid #f5f5f9"
                      >
                        <td
                          style="
                                border: none;
                                width: -webkit-fill-available;
                              "
                        >
                          <i
                            style="color: #2e7d32"
                            class="fas fa-gift ml-4 mr-1"
                            title="Quà tặng"
                          ></i>
                          {{ items.productName }}
                          <i style="font-size: unset; color: unset"
                            >({{ formatMoney(items.productPrice) }})</i
                          >
                        </td>
                        <td style="border: none; font-size: 13px">
                          {{ items.quantity }}
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
              </template>
              <template v-slot:cell(productPrice)="row">
                <div>
                  {{ formatMoney(row.item.productPrice) }}
                  <div v-if="row.item.gifts">
                    <i
                      style="color: #2e7d32"
                      class="fas fa-gift ml-4 mr-1"
                      title="SP quà tặng không tính tiền"
                    >
                      (0)</i
                    >
                  </div>
                </div>
              </template>
            </b-table>

            <div class="mb-5 mt-5">
              <button
                v-show="listBillItem.length > 0"
                style="  fontWeight: '600!important','margin-right': '-10px'"
                type="button"
                class="btn btn-primary font-weight-bolder btn-sm button"
                @click="createBill"
              >
                Cập nhật
              </button>
            </div>
          </template>
        </KTCodePreview>
      </div>
    </div>
  </div>
</template>

<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import { validationMixin } from 'vuelidate';
import { v4 } from 'uuid';
import axios from 'axios';
import '@fortawesome/fontawesome-free/css/fontawesome.css';
import '@fortawesome/fontawesome-free/css/regular.css';
import '@fortawesome/fontawesome-free/css/solid.css';
import moment from 'moment';
import Swal from 'sweetalert2';
import { cmdUrl } from '../../../utils/apiUrl';
export default {
  mixins: [validationMixin],
  data() {
    return {
      form: {},
      isNew: true,
      errors: [],
      count: 0,
      filteredOptionsProduct: [],
      fields: [
        {
          key: 'productId',
          label: 'Id Nhanh',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '5%' }
        },
        {
          key: 'productName',
          label: 'Tên sản phẩm',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '30%' }
        },
        {
          key: 'productPrice',
          label: 'Giá',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '5%' },
          class: 'text-right'
        },
        {
          key: 'discountAmount',
          label: 'Chiết khấu',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '5%' },
          class: 'text-right',
          formatter: value => {
            return this.formatMoney(value);
          }
        },
        {
          key: 'quantity',
          label: 'SL',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '1%' },
          class: 'text-center'
        },
        {
          key: 'totalAmount',
          label: 'Tổng',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '5%' },
          class: 'text-right',
          formatter: value => {
            return this.formatMoney(value);
          }
        },
        {
          key: 'createdAt',
          label: 'Ngày tạo',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '5%' },
          formatter: value => {
            return moment(value).format('YYYY-MM-DD HH:mm:ss');
          }
        }
      ],
      listBillItem: [],
      isSearching: false,
      renderComponent: true,
      billId: null,
      textSearchBill: '',
      billNhanh: null,
      totalMoneyItems: null,
      totalDiscountItems: null,
      billExist: null
    };
  },
  validations: {},
  components: {
    KTCodePreview
  },
  created() {},
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: 'Đồng bộ sản phẩm' }]);
  },
  methods: {
    forceRerender() {
      this.renderComponent = false;

      this.$nextTick(() => {
        this.renderComponent = true;
      });
    },
    validateState(name) {
      if (!this.isNew) {
        const { $dirty, $error } = this.$v.form[name];
        return $dirty ? !$error : null;
      }
    },
    formatMoney(number) {
      return new Intl.NumberFormat('vn-VN').format(number);
    },
    createBill: async function() {
      this.isNew = false;

      let data = {
        billNhanh: this.billNhanh,
        billExist: this.billExist
      };

      let url = cmdUrl.ToolSync.bill;
      axios
        .post(url, data)
        .then(({ data }) => {
          if (data.status) {
            this.makeToastSuccess(data.message);
            this.billNhanh = null;
            this.billExist = null;
            this.textSearchBill = '';
            this.billId = null;
            this.listBillItem = [];
            // setTimeout(() => {
            //   this.$router.push({
            //     name: 'list-inventory-balance'
            //   });
            // }, 1000);
          } else {
            this.makeToastFaile(data.message);
            this.isNew = true;
          }
        })
        .catch(({ response }) => {
          this.makeToastFaile(response.$error);
        });
    },
    makeToastSuccess(message) {
      this.$bvToast.toast(message, {
        title: `Thông báo`,
        variant: 'success',
        solid: true
      });
    },
    makeToastFaile(message) {
      this.$bvToast.toast(message, {
        title: `Thông báo`,
        variant: 'danger',
        solid: true
      });
    },
    onChangeStore() {
      this.listBillItem = [];
    },
    newId() {
      return v4();
    },
    showField(value) {
      return value ? (value.length > 0 ? true : false) : false;
    },
    getBillNhanh() {
      this.textSearchBill = 'loading';
      this.billNhanh = null;
      this.listBillItem = [];
      let url = cmdUrl.ToolSync.billSearch;
      axios
        .get(url, {
          params: {
            id: this.billId
          }
        })
        .then(rs => {
          this.textSearchBill = '';
          if (rs.data.data.billExist) {
            this.billExist = rs.data.data.billExist;
            this.textSearchBill = 'exist';
          }
          if (rs.data.data.billNhanh) {
            this.textSearchBill = 'exist';
            this.billNhanh = rs.data.data.billNhanh;

            this.listBillItem = this.billNhanh.billItems;

            this.totalMoneyItems = this.billNhanh.billItems.reduce(function(
              acc,
              obj
            ) {
              return acc + obj.productPrice;
            },
            0);
            this.totalDiscountItems = this.billNhanh.billItems.reduce(function(
              acc,
              obj
            ) {
              return acc + obj.discountAmount;
            },
            0);
          }
        })
        .catch(err => {
          this.makeToastFaile(err.response.data.message);
          this.textSearchBill = '';
        });
    },
    showDeleteAlert: function(id) {
      Swal.fire({
        title: 'Xóa hóa đơn!',
        text: `Bạn có chắc muốn xóa hóa đơn (${id}) này không ?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: 'rgb(221, 51, 51)',
        cancelButtonColor: '#E5EAEE',
        confirmButtonText: 'Xóa',
        cancelButtonText: '<span style="color:#464E5F">Hủy</span>',
        reverseButtons: true,
        showClass: {
          popup: 'animate__animated animate__fadeInDown'
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp'
        }
      }).then(result => {
        if (result.value) {
          this.removeBill(id);
        }
      });
    },
    removeBill(id) {
      let url = cmdUrl.ToolSync.billId;
      url = url.replace('{id}', id);
      axios
        .delete(url)
        .then(rs => {
          if (rs.data.status) {
            this.makeToastSuccess(rs.data.message);
            this.billNhanh = null;
            this.billExist = null;
            this.textSearchBill = '';
            this.billId = null;
            this.listBillItem = [];
          }
        })
        .catch(err => {
          this.makeToastFaile(err.response.data.message);
        });
    }
  }
};
</script>
<style scoped>
.title-center {
  text-align: center;
}
.autosuggest__results ul {
  list-style: none;
  cursor: pointer;
  margin-bottom: 0;
}

.autosuggest__results {
  position: absolute;
  background-color: #fff;
  z-index: 1;
  width: 100%;
  border: 1px solid #e0e0e0;
  overflow: scroll;
  max-height: 200px;
  position: absolute;
}

.autosuggest__results-container {
  position: relative;
  width: 100%;
}

#autosuggest__input {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  height: calc(1.35em + 1.1rem + 2px);
  padding: 0.55rem 0.75rem;
  font-size: 0.925rem;
  line-height: 1.35;
  border-radius: 0.28rem;
}

#autosuggest__input:focus {
  outline: none !important;
  border: 1px solid #92c5f9;
}

.autosuggest__results-item:hover {
  background-color: #ddd;
}

.autosuggest__results ul li {
  padding: 8px;
}
.input-group-append {
  cursor: pointer;
}

.input-group-append:hover i {
  color: #3699ff;
}

.tdTextAlignPrice {
  text-align: right !important;
  vertical-align: middle;
  font-weight: 600;
}
.table th,
.table td {
  padding: 0.75rem;
  vertical-align: middle;
  border-top: 1px solid #ecf0f3;
}
.table.b-table.table-sm > thead > tr > [aria-sort]:not(.b-table-sort-icon-left),
.table.b-table.table-sm
  > tfoot
  > tr
  > [aria-sort]:not(.b-table-sort-icon-left) {
  background-position: right calc(1.5rem / 2) center;
  padding-right: calc(0.3rem + 0.65em);
}
.info > .row {
  margin-bottom: 10px;
}
.info > .row > .col-4 {
  display: flex;
}
.content-center {
  display: flex;
  /* align-items: center; */
}
#base {
  background: black;
  display: inline-block;
  height: 0px;
  margin-left: -60px;
  margin-top: 5px;
  position: relative;
  width: 60px;
}
#base:before {
  border-top: 10px solid#b50202;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  content: '';
  height: 0;
  left: 0;
  position: absolute;
  top: 15px;
  width: 0;
}
#afterSearch td {
  padding: 6px;
}
</style>
